<template>
    <div id="overlay"
        :style="{ 'background-image': 'linear-gradient(transparent, black 100%), url(' + numbers.bg + ')' }">
        <div class="numbers-container">
            <div class="number">
                <span class="number-value">{{ numbers.firstNum }}</span>
                <br>
                <span class="number-description">{{ numbers.firstTitle }}</span>
            </div>
            <div class="number">
                <span class="number-value">{{ numbers.secondNum }}</span>
                <br>
                <span class="number-description">{{ numbers.secondTitle }}</span>
            </div>
            <div class="number">
                <span class="number-value">{{ numbers.thirdNum }}</span>
                <br>
                <span class="number-description">{{ numbers.thirdTitle }}</span>
            </div>
            <div class="number">
                <span class="number-value">{{ numbers.fourthNum }}</span>
                <br>
                <span class="number-description">{{ numbers.fourthTitle }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheNumbers",
    props: {
        numbers: Object
    }
}
</script>

<style scoped>
#overlay {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    opacity: 0.9;

}

.numbers-container {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    height: 300px;
    margin-left: 10%;
    margin-right: 10%;
}

.number {
    text-align: center;
}

.number-value {
    font-size: 70px;
    font-weight: 700;
    line-height: 100px;
    color: #ffcc00;
    font-family: "PT Sans Caption", Arial, "Helvetica Neue", Helvetica, sans-serif !important;
}

.number-description {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.6em;
    font-weight: 700;
    color: #B8BCC3;
    font-family: "PT Sans Caption", Arial, "Helvetica Neue", Helvetica, sans-serif !important;
}

@media only screen and (max-width: 900px) {
    .numbers-container {
        flex-direction: column;
        align-items: center;
        height: 75vh;
        background-position: center;

    }

    .numbers-container {
        padding-top: 0;
    }

}
</style>