<template>
    <div id="registration" :style="{ 'background-image': 'url(' + register.background + ')' }">
        <div class="card-overlay">
            <h1>{{ register.title }}</h1>
            <h2 v-html="register.subtitle"></h2>
            <a href="#entrance">REGISTRACE</a>

        </div>
    </div>
</template>

<script>
export default {
    name: "TheRegistration",
    props: {
        register: Object
    }
}
</script>

<style scoped>
#registration {
    background-image: color;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.card-overlay {
    padding-top: 150px;
    padding-bottom: 150px;
    background: rgba(255, 168, 0, 0.8);
    text-align: center;
}

h1,
h2 {
    color: white;

}

h1 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -3px;
}

h2 {
    font-size: 15px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 80px;

}

a {
    color: white;
    border: 1px solid white;
    text-decoration: none;
    padding: 20px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 6px;
    line-height: 15px;
    border-radius: 2%;
}

a:hover {
    background-color: white;
    color: rgb(255, 168, 0);

}

@media only screen and (max-width: 900px) {
    #registration {
        background-attachment: inherit;
    }

}
</style>