<template>
    <div id="header-text">
        <img :src="fee.icon" alt="ticket icon" class="title-icon">
        <h1>{{ fee.title }}</h1>
        <h2 v-html="fee.subtitle"></h2>
        <h3 v-html="fee.text"></h3>
    </div>
    <!-- SALES -->
    <div class="price-list" v-if="fee.generateSale">
        <div v-for="(item, index) in sales" :key="index" class="price-item">
            <h2>{{ item.title }}</h2>
            <div class="prices">
                <div class="price">
                    <span class="currency">{{ item.firstCurrency }}</span>
                    <span class="price-value">{{ item.firstPrice }}</span>
                </div>
                <div class="price">
                    <span class="currency">{{ item.secondCurrency }}</span>
                    <span class="price-value">{{ item.secondPrice }}</span>
                </div>
            </div>
            <div class="description">
                <p v-html="item.description"></p>
                <button @click="showPopup(item)" v-if="item.party">{{ item.partyTitle }}</button>
            </div>
            <a :href="item.registrationForm" target="_blank"
                v-if="item.registrationForm"><button>Registrovat</button></a>
            <p class="date">{{ item.dates }}</p>
        </div>
    </div>
    <!-- STANDARD -->
    <div class="price-list" v-if="fee.generateStandard">
        <div v-for="(item, index) in standard" :key="index" class="price-item">
            <h2>{{ item.title }}</h2>
            <div class="prices">
                <div class="price">
                    <span class="currency">{{ item.firstCurrency }}</span>
                    <span class="price-value">{{ item.firstPrice }}</span>
                </div>
                <div class="price">
                    <span class="currency">{{ item.secondCurrency }}</span>
                    <span class="price-value">{{ item.secondPrice }}</span>
                </div>
            </div>
            <div class="description">
                <p v-html="item.description"></p>
                <button @click="showPopup(item)" v-if="item.party">{{ item.partyTitle }}</button>
            </div>
            <a :href="item.registrationForm" target="_blank"
                v-if="item.registrationForm"><button>Registrovat</button></a>
            <p class="date">{{ item.dates }}</p>
        </div>
    </div>
    <!-- LAST MINUTE -->
    <div class="price-list" v-if="fee.generateLastMinute">
        <div v-for="(item, index) in lastMinute" :key="index" class="price-item">
            <h2>{{ item.title }}</h2>
            <div class="prices">
                <div class="price">
                    <span class="currency">{{ item.firstCurrency }}</span>
                    <span class="price-value">{{ item.firstPrice }}</span>
                </div>
                <div class="price">
                    <span class="currency">{{ item.secondCurrency }}</span>
                    <span class="price-value">{{ item.secondPrice }}</span>
                </div>
            </div>
            <div class="description">
                <p v-html="item.description"></p>
                <button @click="showPopup(item)" v-if="item.party">{{ item.partyTitle }}</button>
            </div>
            <a :href="item.registrationForm" target="_blank"
                v-if="item.registrationForm"><button>Registrovat</button></a>
            <p class="date">{{ item.dates }}</p>
        </div>
    </div>

    <!-- Vyskakovací bublina -->
    <div class="popup" v-if="popupVisible" @click="closePopupOnOutsideClick">
        <div class="popup-content">
            <span class="close" @click="closePopup">&times;</span>
            <h2>{{ popupText.partyTitle }}</h2>
            <p v-html="popupText.partyDescription"></p>
        </div>
    </div>

    <div id="payment-condition">
        <div class="conditions">
            <h1 v-html="fee.termsCondititonTitle"></h1>
            <p v-html="fee.termsCondition" class="condition-desc"></p>
        </div>
        <div class="conditions">
            <h1 v-html="fee.cancelTitle"></h1>
            <p v-html="fee.cancel" class="condition-desc"></p>
        </div>
        <div class="conditions">
            <h1 v-html="fee.privacyPolicyTitle"></h1>
            <p v-html="fee.privacyPolicy" class="condition-desc"></p>
        </div>
        <div class="conditions">
            <h1 v-html="fee.placeTitle"></h1>
            <p v-html="fee.place" class="condition-desc"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntranceFee",
    props: {
        fee: Object
    },
    data() {
        return {
            sales: this.fee.sale,
            standard: this.fee.standard,
            lastMinute: this.fee.lastMinute,
            popupVisible: false,
            popupText: {}
        };
    },
    methods: {
        showPopup(item) {
            this.popupVisible = true;
            this.popupText = item

        },
        closePopup() {
            this.popupVisible = false;
            this.popupText = {}
        },
        closePopupOnOutsideClick() {
            this.popupText = {}
            this.closePopup();

        }
    }
};
</script>

<style scoped>
#header-text {
    text-align: center;
    padding-bottom: 50px;
}

#ticket {
    font-size: 55px !important;
    color: rgb(255, 168, 0);

}

#header-text h1 {
    color: #262627;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: -2.5px;
    line-height: 55px;
    margin-bottom: 10px;
}

#header-text h2 {
    color: rgb(184, 188, 195);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}


#header-text h3 {
    color: rgb(81, 84, 91);
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
}

.price-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.price-list h2 {
    color: #ffa800;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.price-item {
    width: 360px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e9e9e9;
}

.prices {
    margin-bottom: 10px;
}

.price {
    margin-right: 10px;
    font-size: 70px;
    font-weight: 700;
    line-height: 1;
    color: #262627;
}

.currency {
    font-size: 45px;
    vertical-align: top;
    line-height: 1;
    color: rgb(38, 38, 39);
}

.description,
.date {
    font-size: 15px;
    font-weight: 400;
    line-height: 29px;
    color: rgb(81, 84, 91);
    margin-top: 15px;
    margin-bottom: 15px;
}


button {
    background-color: #ffa800;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: auto;
}

button:hover {
    background-color: #ffb41f;
}

.price-list a {
    color: inherit;
    text-decoration: none;
}

#payment-condition {
    margin-top: 50px;
}

.conditions h1 {
    color: rgb(38, 38, 39);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.condition-desc {
    color: rgb(81, 84, 91);
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup h2{
    text-align: center;
    font-weight: bold;
    color: #ffa800;
    padding: 10px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.close:hover {
    color: red;
}
</style>
