<template>
  <span id="feedback-top"></span>
  <div id="header-text">
    <span id="feedbck" class="pi pi-comments"></span>
    <h1>{{ ratings.title }}</h1>
    <h2 v-html="ratings.subtitle"></h2>
  </div>
  <div class="ratings-container">
    <div v-for="(rating, index) in displayedRatings" :key="index" class="rating-item">
      <div class="rating-box">
        <p class="rating-text">{{ rating.rating }}</p>
        <div class="user-info">
          <img :src="rating.avatar" alt="User Avatar" class="avatar-img" />
          <p class="user-name">{{ rating.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="show-more-btn">
    <button v-if="showMoreButton" @click="toggleShowMore" class="show-more-button">
      {{ showMoreText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "RatingsComponent",
  props: {
    ratings: Object,
  },
  data() {
    return {
      displayedRatings: [],
      showMoreButton: true,
      showMoreText: "Zobrazit více",
      count: this.ratings.numberOnPage,
    };
  },
  mounted() {
    this.displayedRatings = this.ratings.fback.slice(0, this.count);
    if (this.ratings.fback.length <= this.count) {
      this.showMoreButton = false;
    }
  },
  methods: {
    toggleShowMore() {
      if (this.displayedRatings.length === this.count) {
        const element = document.getElementById("feedback-top");
        const top = element.offsetTop;
        window.scrollTo(0, top - 90);
        this.displayedRatings = this.ratings.fback;
        this.showMoreText = "Zobrazit méně";
      } else {
        const element = document.getElementById("feedback-top");
        const top = element.offsetTop;
        window.scrollTo(0, top - 90);
        this.displayedRatings = this.ratings.fback.slice(0, this.count);
        this.showMoreText = "Zobrazit více";
      }
    },
  },
};
</script>

<style scoped>
#header-text {
  display: block;
  text-align: center;
}

#feedbck {
  font-size: 55px !important;
  color: rgb(255, 168, 0);
}

#header-text h2 {
  color: #b8bcc3;
  line-height: normal;
  font-weight: 400;
  font-size: 15px;
}

.ratings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
}

.rating-item {
  width: 30%;
  margin: 10px;
}

.rating-box {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f5f6;
  border: 1px solid #e9e9e9;
}

.rating-text {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
  color: rgb(81, 84, 91);
}

.user-info {
  display: flex;
  align-items: center;
}

.avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-name {
  margin-left: 10px;
  font-size: 13px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: rgb(255, 168, 0);
}

.show-more-button {
  background-color: white;
  border: 0px;
  color: rgb(255, 168, 0);
  font-weight: bold;
  font-size: 16px;
}

.show-more-button:hover {
  cursor: pointer;
  color: #ffb41f;
}

.show-more-btn {
  margin-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .rating-item {
    width: 90%;
  }
}
</style>
