<template>
  <div class="header" :style="{ 'background-image': 'url(' + source.header.background + ')' }">
    <the-header :headerText="source.header" />
  </div>
  <div v-if="source.header.generateFullPage">
    <the-menu :headerText="source.header" :activeBlocks="source.visibility"></the-menu>
    <div class="introduction">
      <div v-if="source.visibility.firstBlock" id="intro" class="component-section">
        <the-introduction :intro="source.introduction"></the-introduction>
      </div>
      <div v-if="source.visibility.numbers" id="numbers" class="component-section">
        <the-numbers :numbers="source.introduction"></the-numbers>
      </div>
      <div v-if="source.visibility.speakers" id="speakers" class="component-section">
        <speakers-page :userList="source.speakers" :config="source.config"></speakers-page>
      </div>
      <div v-if="source.visibility.program" id="program" class="component-section">
        <conference-schedule :program="source.programs"></conference-schedule>
      </div>
    </div>
    <div v-if="source.visibility.ctf" id="ctf" class="component-section">
      <capture-the-flag :ctf="source.ctf"></capture-the-flag>
    </div>
    <div v-if="source.visibility.registration" id="entrance" class="component-section">
      <entrance-fee :fee="source.entrance"></entrance-fee>
    </div>
    <div v-if="source.visibility.feedback" id="feedback" class="component-section">
      <the-feedback :ratings="source.feedback"></the-feedback>
    </div>
    <div v-if="source.visibility.register" id="register" class="component-section">
      <the-registration :register="source.registration"></the-registration>
    </div>
    <div v-if="source.visibility.gallery" id="photogallery" class="component-section">
      <the-photogallery :gallery="source.photogallery"></the-photogallery>
    </div>
    <div v-if="source.visibility.partners" id="partner">
      <the-partners :partners="source.partners"></the-partners>
    </div>
    <div v-if="source.visibility.contacts" id="contact">
      <contact-address :contact="source.footer"></contact-address>
    </div>
    <div id="footer">
      <the-footer-bar :link="source.header"></the-footer-bar>
    </div>
  </div>
  <div id="cookie-banner">
    <cookie-banner></cookie-banner>
  </div>
</template>

<script>
import source from "@/content/content.json";
import TheHeader from '@/components/TheHeader.vue';
import TheMenu from "./components/TheMenu.vue";
import TheNumbers from "./components/TheNumbers.vue";
import SpeakersPage from "./components/SpeakersPage.vue";
import EntranceFee from "./components/EntranceFee.vue";
import TheFeedback from "./components/TheFeedback.vue";
import ThePhotogallery from "./components/ThePhotogallery.vue";
import ThePartners from "./components/ThePartners.vue";
import CaptureTheFlag from "./components/CaptureTheFlag.vue";
import TheRegistration from "./components/TheRegistration.vue";
import ConferenceSchedule from "./components/ConferenceSchedule.vue";
import TheIntroduction from "./components/TheIntroduction.vue";
import ContactAddress from "./components/ContactAddress.vue";
import TheFooterBar from "./components/TheFooterBar.vue";
import CookieBanner from "./components/CookieBanner.vue";

export default {
  name: "App",
  data() {
    return {
      source: source
    }
  },
  components: {
    TheHeader,
    TheMenu,
    TheNumbers,
    SpeakersPage,
    EntranceFee,
    TheFeedback,
    ThePhotogallery,
    ThePartners,
    CaptureTheFlag,
    TheRegistration,
    ConferenceSchedule,
    TheIntroduction,
    ContactAddress,
    TheFooterBar,
    CookieBanner
  },
}
</script>

<style>
* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}

#numbers {
  margin-top: 200px;
  margin-bottom: 30px;

}

div.header {
  height: 100vh !important;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

div.introduction {
  background-color: white;
  background-size: cover;
  margin-top: 90px;
}

#intro {
  margin-top: 35px;
}

.component-section {
  margin-bottom: 100px;
}

.second-color {
  color: #ffa800;
}

.block-justify {
  text-align: justify !important;
}

a {
  color: #ffa800;
}

#speakers,
#program,
#ctf,
#photogallery,
#feedback,
#partner,
#register,
#entrance {
  scroll-margin-top: 90px
}

.title-icon {
  width: 75px;
  height: 75px;

}

@media only screen and (max-width: 900px) {
  div.header {
    background-attachment: inherit;
  }

}
</style>
