<template>
    <div class="footer">
        <div class="info-container">
            <div class="left-info">
                <div class="bottom-links">
                    <span class="break-line"><a href="#contact">KONTAKT</a></span>
                    <span class="break-line"><a href="https://www.cyber-rangers.com/cs/stranka/privacy-policy-5u9hH0" target="_blank">PRIVACY POLICY</a></span>
                    <span class="break-line"><a href="https://www.cyber-rangers.com/" target="_blank">CYBER RANGERS</a></span>
                </div>
            </div>
            <div class="right-info">
                <span class="break-line"><social-buttons :links="link.links"></social-buttons></span>
                <span class="copyright break-line">Práva vyhrazena &copy; {{ year }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import SocialButtons from "./SocialButtons.vue";

export default {
    name: "TheFooterBar",
    props: {
        link: Object
    },
    data() {
        return {
            year: new Date().getFullYear()
        }
    },
    components: {
        SocialButtons
    }
};
</script>

<style scoped>
.footer {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181A26;
    color: white;
    padding-bottom: 10px;
    padding-top: 10px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    width: 75%;
    flex-wrap: wrap;
}

h2 {
    color: rgb(255, 168, 0);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
}

p {
    color: rgb(184, 188, 195);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}

.copyright {
    margin-left: 30px;
    color: #B8BCC3;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
}

a {
    color: white;
    text-decoration: none;
    margin-right: 50px;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.3em;
}

a:hover {
    color: #ffa800;
    transition: color 0.15s ease, background 0.15s ease, border 0.15s ease, opacity 0.15s ease;
}

@media only screen and (max-width: 768px) {
    .footer {
        height: 200px;
    }

    a {
        margin: 0;
    }

    .copyright {
        margin: 0;
        padding-top: 10px;
    }

    .info-container {
        flex-direction: column;
        text-align: left;
    }

    .right-info {
        margin-top: 10px;
    }

    .break-line {
        display: block;
        margin-left: 0 !important;
        margin-top: 10px;
    }
}
</style>
