<template>
    <div class="company-info">
        <div class="logo-container">
        </div>
        <div class="info-container">
            <div class="left-info">
                <h2>{{ contact.invoicingTitle }}</h2>
                <p v-html="contact.invoicingAddress"></p>
            </div>
            <div class="right-info">
                <h2>{{ contact.contactTitle }}</h2>
                <p>
                    <span><i class="contact-icon pi pi-envelope"></i> <a :href="'mailto:' + contact.email">{{ contact.email }}</a></span>
                    <br>
                    <span><i class="contact-icon pi pi-phone"></i> {{ contact.phone }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactAddress",
    props: {
        contact: Object
    }
};
</script>

<style scoped>
.company-info {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #012E40;
    color: white;
    padding-bottom: 40px;
    padding-top: 40px;
}

.logo-container {
    margin-bottom: 20px;
}

.logo {
    margin-top: 20px;
    margin-bottom: 10px;
    max-height: 100px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    width: 75%;
}

.contact-icon {
    color: rgb(255, 168, 0);
}

h2 {
    color: rgb(255, 168, 0);
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
}

p {
    color: rgb(184, 188, 195);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}


@media only screen and (max-width: 768px) {
    .info-container {
        flex-direction: column;
        align-items: left;
    }

    .right-info {
        margin-top: 30px;
    }
}
</style>