<template>
    <span id="gallery-top"></span>
    <div id="header-text">
        <span id="gallery-icon" class="pi pi-images"></span>
        <h1>{{ gallery.title }}</h1>
        <h2 v-html="gallery.subtitle"></h2>
    </div>
    <div class="gallery">
        <div v-for="(photo, index) in visiblePhotos" :key="index" class="photo-wrapper" @mouseover="zoomIn(index)"
            @mouseleave="zoomOut" @click="openPopup(index)">
            <img :src="photo.thumbnail" :alt="'Fotografie ' + (index + 1)" class="photo" />
            <button v-if="photo.showPopup" @click="openPopup(index)" class="popup-button">
                <span id="feedbck" class="pi pi-window-maximize"></span>
            </button>
        </div>
    </div>
    <div v-if="moreToShow" class="show-more-button" @click="toggleMore">{{ showMoreText }}</div>
    <div v-if="popupIndex !== null" class="popup" @click="closePopup">
        <img :src="photos[popupIndex].photo" :alt="'Fotografie ' + (popupIndex + 1)" class="popup-photo" />
    </div>
</template>

<script>
export default {
    name: "ThePhotogallery",
    props: {
        gallery: Object,
    },
    data() {
        return {
            photos: this.gallery.gallery,
            popupIndex: null,
            showMore: false,
            visiblePhotoCount: this.gallery.numberOnPage,
        };
    },
    computed: {
        visiblePhotos() {
            return this.showMore ? this.photos : this.photos.slice(0, this.visiblePhotoCount);
        },
        moreToShow() {
            return this.photos.length > this.visiblePhotoCount;
        },
        showMoreText() {
            return this.showMore ? 'Zobrazit méně' : 'Zobrazit více';
        },
    },
    methods: {
        zoomIn(index) {
            this.photos[index].showPopup = true;
        },
        zoomOut() {
            this.photos.forEach((photo) => {
                photo.showPopup = false;
            });
        },
        openPopup(index) {
            this.popupIndex = index;
        },
        closePopup() {
            this.popupIndex = null;
        },
        toggleMore() {
            const element = document.getElementById("gallery-top");
            const top = element.offsetTop;
            window.scrollTo(0, top - 90);
            this.showMore = !this.showMore;
        },
    },
};
</script>

<style scoped>
#header-text {
    display: block;
    text-align: center;
}

#gallery-icon {
    font-size: 55px !important;
    color: rgb(255, 168, 0);
}

#header-text h2 {
    color: #B8BCC3;
    line-height: normal;
    font-weight: 400;
    font-size: 15px;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.photo-wrapper {
    position: relative;
    margin: 10px;
    cursor: pointer;
}

.photo {
    width: 200px;
    height: auto;
    transition: transform 0.3s;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup-photo {
    max-width: 90%;
    max-height: 90%;
}

.popup-button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 168, 0);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.5s;
}

.photo-wrapper:hover .photo {
    transform: scale(1.1);
}

.photo-wrapper:hover .popup-button {
    opacity: 1;
}

.show-more-button {
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    color: rgb(255, 168, 0);
    font-weight: bold;
    font-size: 16px;
}

.show-more-button:hover {
    color: #ffb41f;
}
</style>