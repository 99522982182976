<template>
  <div id="header-text">
    <span id="microphone" class="pi pi-microphone"></span>
    <h1>{{ intro.title }}</h1>
    <h2 v-html="intro.subtitle"></h2>
  </div>

  <div class="intro-descr">
    <h3>{{ intro.intro }}</h3>
    <p v-html="intro.description"></p>
  </div>

  <div class="intro-menu">
    <div class="horizontal-menu" v-if="!isMobile">
      <div v-for="(item, index) in menuItems" :key="index" @click="selectItem(item)"
        :class="{ 'menu-item': true, active: item === selectedItem }">
        {{ item.label }}
      </div>
    </div>
    <div class="horizontal-menu-phone" v-else>
      <select v-model="selectedItem" name="menuItems" id="menuItems">
        <option v-for="(item, index) in menuItems" :value="item" :key="index"
          :class="{ active: item === selectedItem }">
          {{ item.label }}
        </option>
      </select>
    </div>
    <div class="content-container">
      <div class="image-container" v-if="selectedItem.image">
        <img :src="selectedItem.image" alt="Selected Item" v-if="selectedItem" />
      </div>
      <div class="text-container">
        <h2>{{ selectedItem.title }}</h2>
        <p v-html="selectedItem.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheIntroduction",
  props: {
    intro: Object,
  },
  data() {
    return {
      selectedItem: null,
      menuItems: this.intro.sections,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = window.innerWidth <= 900;
    window.addEventListener("resize", this.checkWindowSize);
    this.selectedItem = this.menuItems[0];
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    checkWindowSize() {
      this.isMobile = window.innerWidth <= 900;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkWindowSize);
  },
};
</script>

<style scoped>
#header-text {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

#microphone {
  font-size: 55px !important;
  color: rgb(255, 168, 0);
}

.intro-menu {
  background-color: #012E40;
  color: white;

}

#header-text h2 {
  color: #b8bcc3;
  line-height: normal;
  font-weight: 400;
  font-size: 15px;
}

.intro-descr {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: justify;
  margin-left: 5%;
  margin-right: 5%;

}

.intro-descr h3 {
  text-align: center;
  margin-bottom: 10px;
}

.horizontal-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 7px;
  border: 0.5px solid #b8bcc3;
  font-weight: bold;
  margin-top: 46px;
  margin-bottom: 20px;
}

.menu-item:hover {
  color: white;
  background-color: rgb(255, 168, 0);
}

.menu-item.active {
  background-color: rgb(255, 168, 0);
  color: white;
  border: 0;
}

.horizontal-menu-phone {
  text-align: center;
}

.content-container {
  display: flex;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;

}

.image-container {
  flex: 1;
}

.image-container img {
  max-width: 75%;
  max-height: 75%;
}

.text-container {
  flex: 1;
  padding-left: 20px;
  text-align: center;
}

h2 {
  text-align: center;
  margin-bottom: 10px;
}

.text-container {
  text-align: justify;
}

select {
  border: 0.5px solid rgb(255, 168, 0);
  padding: 10px;
  color: black;
  background-color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 2px;


}

@media only screen and (max-width: 900px) {
  .content-container {
    flex-direction: column;
  }

  .image-container img {
    width: 100%;
  }

  .horizontal-menu {
    display: none;
  }

  .intro-menu {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
