<template>
  <header>
    <div id="button-links">
      <social-buttons :links="headerText.links" />
    </div>
    <div id="rectangle">
      <div id="banner" class="drop-shadow">
        <p>
          <span class="date-place drop-shadow"><i class="pi pi-calendar"></i>{{ headerText.date }}</span><span
            class="date-place drop-shadow"><i class="pi pi-building"></i>{{ headerText.place }}</span>
        </p>
        <p class="drop-shadow"><img :src="headerText.logo" alt="CYB3R Days" id="logo" /></p>
        <div id="subtitle">
          <p class="drop-shadow">{{ headerText.subtitle }}</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script scoped>
import SocialButtons from "./SocialButtons.vue";
export default {
  name: "TheHeader",
  props: {
    headerText: Object,
  },
  components: {
    SocialButtons,
  },
};
</script>

<style scoped>
* {
  color: white;
}

#button-links {
  text-align: center;
  padding-top: 45px;
}

#subtitle {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  letter-spacing: 0.09em;
  margin-bottom: 10px;
  line-height: 1.1;
}

.drop-shadow {
  -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 1));
}

#banner {
  text-align: center;
  padding-top: 75px;
  /*
  -webkit-filter: drop-shadow(1px 1px 50px rgba(0, 0, 0, 0.4))
    drop-shadow(-1px -50px 50px rgba(0, 0, 0, 0.4))
    drop-shadow(1px -100px 5px rgba(0, 0, 0, 0.4))
    drop-shadow(-30px 1px 5px rgba(0, 0, 0, 0.1))
    drop-shadow(30px 100px 5px rgba(0, 0, 0, 0.4))
    drop-shadow(-1px 50px 5px rgba(0, 0, 0, 0.4))
    drop-shadow(0px 1px 50px rgba(0, 0, 0, 0.1))
    drop-shadow(0px -1px 50px rgba(0, 0, 0, 0.5));
    */
}

.date-place {
  letter-spacing: 0.2em;
  font-weight: 700;
  font-size: 19px;
  margin-left: 10px;
  margin-bottom: 8px;
  line-height: 1.7;
}

#rectangle {
  margin-top: 20vh;
  width: 100%;
  height: 45vh;
  border-radius: 100%;
}

i {
  color: rgb(255, 168, 0);
}

@media only screen and (max-width: 900px) {
  #subtitle {
    font-size: 15px;
  }

  .date-place {
    font-size: 15px;
  }

  #logo {
    width: 100%;
  }

  #banner {
    padding-top: 50px;
    filter: none !important;
  }

  #rectangle {
    margin-top: 50%;
    height: 30vh;
  }
}
</style>
