<template>
    <div v-if="!isCookieAccepted && !isCookieSet" class="cookie-banner">
        <p>{{ cookieMessage }}</p>
        <button @click="acceptCookies(true)" class="cookie-btn">Souhlasím</button>
        <button @click="acceptCookies(false)" class="cookie-btn">Nesouhlasím</button>
    </div>
</template>

<script>
export default {
    name: "CookieBanner",
    data() {
        return {
            cookieAcceptedKey: "cookies_accepted",
            isCookieAccepted: false,
            isCookieSet: false,
            cookieMessage: "Na našem webu používáme soubory cookies, kliknutím na tlačítko „Souhlasím“ dáváte svůj souhlas s použitím cookies pro účely: Marketingové, Měřicí a analytické.",
        };
    },
    mounted() {
        this.isCookieSet = this.getCookie(this.cookieAcceptedKey) !== "";
    },
    methods: {
        acceptCookies(accept) {
            this.isCookieAccepted = true;
            this.cookieSet = true;
            this.setCookie(this.cookieAcceptedKey, accept.toString(), 365);
        },
        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/;SameSite=Lax";
        },
        getCookie(name) {
            const cookieName = name + "=";
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                while (cookie.charAt(0) === " ") {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(cookieName) === 0) {
                    return cookie.substring(cookieName.length, cookie.length);
                }
            }
            return "";
        },
    },
};
</script>

<style scoped>
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0000006e;
    padding: 15px;
    text-align: center;
    color: white;
}

.cookie-btn {
    margin-right: 10px;
    margin-top: 10px;
    background-color: #ffa800;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.cookie-btn:hover {
    background-color: #ffb41f;
}
</style>