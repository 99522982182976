<template>
    <a :href="links[0].link" target="_blank"><span><b-icon-twitter-x /></span></a>
    <a :href="links[1].link" target="_blank"><span><b-icon-facebook /></span></a>
    <a :href="links[2].link" target="_blank"><span><b-icon-github /></span></a>
    <a :href="links[3].link" target="_blank"><span><b-icon-linkedin /></span></a>
</template>

<script>
export default {
    name: "SocialButtons",
    props: {
        links: Object,
    },
};
</script>

<style scoped>
span {
    margin-right: 35px;
}

a {
    text-decoration: none;
    color: rgb(255, 168, 0);
}
</style>
