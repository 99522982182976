<template>
    <div id="ctf-block" :style="{ 'background-image': 'url(' + ctf.background + ')' }">
        <div class="card-overlay">
            <div v-html="ctf.description" class="ctf-description"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CaptureTheFlag",
    props: {
        ctf: Object
    }
}
</script>

<style scoped>
#ctf-block {
    background-image: color;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.card-overlay {
    background: rgba(0, 0, 0, 0.8);
}

.ctf-description {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;

}

.ctf-description>>>h1 {
    color: #ffa800;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 55px;
}
</style>