<template>
    <div id="header-text">
        <span id="users" class="pi pi-users"></span>
        <h1>{{ users.title }}</h1>
        <h2 v-html="users.subtitle"></h2>
    </div>
    <div class="user-list">
        <div v-for="(user, index) in sortedSpeakers" :key="index" class="user-card">
            <div class="user-avatar">
                <span v-if="user.photo"><img :src="user.photo" alt="User Avatar" class="avatar-img"></span>
                <span v-else><img :src="config.defaultSpeakAvatar" alt="User Avatar" class="avatar-img"></span>
            </div>
            <div class="user-info">
                <h2>{{ user.name }}</h2>
                <p id="user-position">{{ user.position }}</p>
                <p id="user-company">{{ user.company }}</p>
                <template v-if="user.showMore">
                    <p class="bio-content">{{ user.description }}</p>
                    <button @click="toggleShowMore(user)"><span class="arrow">&uarr;</span></button>
                </template>

                <template v-else>
                    <p v-if="user.description.length > 200" class="bio-preview">{{
                        user.description.substring(0, 200)
                        }}...</p>
                    <p v-else class="bio-content">{{ user.description }}</p>
                    <button v-if="user.description.length > 200" @click="toggleShowMore(user)"><span
                            class="arrow">&darr;</span></button>
                </template>
                <p v-if="user.linkedin"><a :href="user.linkedin" target="_blank" class="social-link"><span
                            class="pi pi-linkedin"></span></a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SpeakersPage",
    props: {
        userList: Object,
        config: Object
    },
    data() {
        return {
            users: this.userList
        };
    },
    computed: {
        sortedSpeakers() {
            return this.userList.speaker.slice().sort((a, b) => {
                const nameA = a.photo.split('/').pop().split('.')[0];
                const nameB = b.photo.split('/').pop().split('.')[0];
                return nameA.localeCompare(nameB);
            });
        }
    },
    methods: {
        toggleShowMore(user) {
            user.showMore = !user.showMore;
        }
    }
};
</script>


<style scoped>
.arrow:hover {
    color: rgb(255, 168, 0);

}

#header-text {
    display: block;
    text-align: center;
    margin-top: 90px;
}

#users {
    font-size: 55px !important;
    color: rgb(255, 168, 0);

}

#header-text h2 {
    color: #B8BCC3;
    line-height: normal;
    font-weight: 400;
    font-size: 15px;
}

#show-more-btn {
    margin-top: 10px;
    color: rgb(184, 188, 195);

}

#show-more-btn:hover {
    color: rgb(255, 168, 0);
}

.user-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-left: 5%;
    margin-right: 5%;
}

.user-card {
    width: 300px;
    margin: 20px;
}

.user-avatar {
    text-align: center;
}

.avatar-img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.user-info {
    margin-top: 10px;
    text-align: center;

}

.user-info h2 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 14px;
    letter-spacing: 0.3em;
    color: #ffa800;
    margin-bottom: 10px;
}

#user-position {
    font-size: 14px;
    font-weight: 400;
    color: rgb(184, 188, 195);
    margin-top: 10px;
    margin-bottom: 10px;
}

#user-company {
    font-size: 13px;
    font-weight: 500;
    color: rgb(184, 188, 195);
    margin-bottom: 10px;
}

.bio-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
    font-weight: 400;
    color: #51545b;
    text-align: center;
    font-size: 15px;
}

.bio-content {
    margin-top: 10px;
    font-weight: 400;
    color: #51545b;
    text-align: center;
    font-size: 15px;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.social-link {
    display: block;
    margin-top: 10px;
    text-align: center;
    color: #ffa800;

}

@media only screen and (max-width: 900px) {
    .user-list {
        flex-direction: column;
        align-items: center;
    }

    .user-card {
        width: 80%;
    }
}
</style>