import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import "./assets/styles.scss";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";

createApp(App)
  .use(PrimeVue)
  .use(BootstrapIconsPlugin)
  .component("Dropdown", Dropdown)
  .component("Button", Button)
  .mount("#app");
