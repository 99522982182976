<template>
    <nav class="menu sticky">
        <div class="menu-background">
            <button class="toggle-menu" v-if="isMobile" @click="toggleMenu">
                <span id="hamburger-btn" v-if="!isMenuOpen">&#9776;</span>
                <span id="close-btn" v-else>&times;</span>
            </button>
            <ul class="menu-list" :class="{ 'active': isMenuOpen }" v-if="!isMobile || isMenuOpen">
                <li>
                    <a href="#" @click="toggleMenu"><img :src="headerText.crLogoWhite" alt="Logo" class="logo"></a>
                </li>
                <li v-for="(item) in filteredMenuItems" :key="item.id">
                    <a :href="item.link" @click="setActive(item.id)" :class="{ 'active': item.id === itemActive }">{{ item.label }}</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: "TheMenu",
    props: {
        headerText: Object,
        activeBlocks: Object
    },
    data() {
        return {
            menuItems: [
                { id: 1, label: "PŘEDNÁŠEJÍCÍ", link: "#speakers", block: "speakers" },
                { id: 2, label: "PROGRAM", link: "#program", block: "program" },
                { id: 3, label: "CTF", link: "#ctf", block: "ctf" },
                { id: 4, label: "GALERIE", link: "#photogallery", block: "gallery" },
                { id: 5, label: "FEEDBACK", link: "#feedback", block: "feedback" },
                { id: 6, label: "PARTNEŘI", link: "#partner", block: "partners" },
                { id: 7, label: "KONTAKT", link: "#contact", block: "contacts" },
                { id: 8, label: "REGISTRACE", link: "#register", block: "register" },
                { id: 9, label: "PRO ÚČASTNÍKY", link: "https://portal.cyb3r-days.com/", block: "forParticipants" }
            ],
            isSticky: false,
            originalOffsetTop: null,
            isMenuOpen: false,
            isMobile: false,
            itemActive: null
        };
    },
    computed: {
        filteredMenuItems() {
            if (!this.activeBlocks) return this.menuItems;
            return this.menuItems.filter(item => this.activeBlocks[item.block]);
        }
    },
    mounted() {
        this.originalOffsetTop = this.$el.offsetTop;
        window.addEventListener('scroll', this.handleScroll);
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.checkIfMobile);
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > this.originalOffsetTop) {
                this.isSticky = true;
            } else {
                this.isSticky = false;
            }
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        checkIfMobile() {
            this.isMobile = window.innerWidth < 900;
        },
        setActive(itemId) {
            this.itemActive = itemId;
            this.isMenuOpen = false;
        }
    }
};
</script>


<style scoped>
#hamburger-btn {
    font-size: 35px;
}

#close-btn {
    font-size: 45px;

}

span {
    color: #ffa800;
    font-weight: 600;
}

.menu {
    display: flex;
    justify-content: center;
}

.menu-background {
    background-color: #181A26;
    padding: 10px;
    height: 90px;
    width: 100vw;
}

a:hover {
    color: #ffa800;
    transition: color 0.15s ease, background 0.15s ease, border 0.15s ease, opacity 0.15s ease;
}

.logo {
    max-width: 100px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

}

.toggle-menu {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    display: none;
}

.menu-list {
    list-style: none;
    margin-top: 15px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-list li {
    margin-right: 35px;
}

.menu-list a {
    color: white;
    text-decoration: none;
    font-family: "PT Sans Caption", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 11px;
    letter-spacing: 0.26em;
    line-height: 1.5625;
    font-weight: bold;
}

a:hover {
    color: #ffa800;
    transition: color 0.15s ease, background 0.15s ease, border 0.15s ease, opacity 0.15s ease;
}

.logo {
    max-width: 100px;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
}


@media only screen and (max-width: 900px) {
    .menu-background {
        background-color: #26262700;
    }

    .toggle-menu {
        display: block;
        position: sticky;
        top: 0;
        right: 0;
        z-index: 1001;
    }

    .menu-list {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #262627;
        z-index: 1000;
    }

    .menu-list.active {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .menu-list li {
        margin-right: 0;
        margin-bottom: 15px;
    }
}
</style>