<template>
    <div id="partners"
        :style="{ 'background-image': 'url(' + partners.background + ')' }">
        <div class="partners-header header-top">
            <h1>{{ partners.organiserTite }}</h1>
            <div class="image-container">
                <div class="image image-org" v-for="(image, index) in partners.organiser" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
        <div class="partners-header" v-if="partners.showPatronage">
            <h1>{{ partners.patronageTitle }}</h1>
            <div class="image-container">
                <div class="image image-patron" v-for="(image, index) in partners.patronage" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
        <div class="partners-header" v-if="partners.showPlatina">
            <h1>{{ partners.platinaTitle }}</h1>
            <div class="image-container">
                <div class="image image-platina" v-for="(image, index) in partners.platina" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
        <div class="partners-header" v-if="partners.showGold">
            <h1>{{ partners.goldTitle }}</h1>
            <div class="image-container">
                <div class="image image-gold" v-for="(image, index) in partners.gold" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
        <div class="partners-header" v-if="partners.showSilver">
            <h1>{{ partners.silverTitle }}</h1>
            <div class="image-container image-silver">
                <div class="image" v-for="(image, index) in partners.silver" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
        <div class="partners-header" v-if="partners.showMedia">
            <h1>{{ partners.mediaTitle }}</h1>
            <div class="image-container">
                <div class="image image-media" v-for="(image, index) in partners.media" :key="index">
                    <span v-if="image.link">
                        <a :href="image.link" target="_blank">
                            <img :src="image.logo" :alt="'Image ' + (index + 1)" />
                        </a>
                    </span>
                    <span v-else><img :src="image.logo" :alt="'Image ' + (index + 1)" /></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThePartners",
    props: {
        partners: Object
    }
}
</script>

<style scoped>
#partners {
    background-image: color;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    height: 100%;

}

.header-top {
    padding-top: 40px;
}

.partners-header {
    color: #ffa800;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.06em;
    line-height: 44px;
    text-align: center;
    padding-bottom: 50px;

}

.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
}

.image {
    margin: 50px;
    object-fit: cover;
}

.image-org img {
    max-width: 60%;
    max-height: 60%;
}


.image-patron img {
    max-width: 80%;
    max-height: 80%;
}

.image-platina img {
    max-width: 80%;
    max-height: 80%;
}

.image-gold img {
    max-width: 75%;
    max-height: 75%;
}

.image-silver img {
    max-width: 70%;
    max-height: 70%;
}

.image-media img {
    max-width: 60%;
    max-height: 60%;
}

@media only screen and (max-width: 900px) {
  #partners {
    background-attachment:inherit;
  }

}
</style>